<template>
  <v-form class="board-password">
    <v-row>
      <v-col cols="12">
        <BaseInput
          v-model.trim="password"
          :append-icon="show ? svgEye : svgEyeOff"
          :label="$t('share.link_password')"
          :type="show ? 'text' : 'password'"
          outlined
          dense
          :validations="$v"
          name="password"
          autocomplete="false"
          @click:append="show = !show"
        ></BaseInput>
      </v-col>
      <v-col cols="12" class="text-right">
        <BaseButton outlined color="gray" @click="submitCancel">
          {{ $t("common.cancel") }}
        </BaseButton>
        <BaseButton class="ml-2" @click="submit">
          {{ $t("common.ok") }}
        </BaseButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mdiEyeOff, mdiEye } from "@mdi/js";

export default {
  name: "BoardPasswordForm",
  data() {
    return {
      password: "",
      show: false,
      svgEyeOff: mdiEyeOff,
      svgEye: mdiEye
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit("onSubmit", this.password);
      this._clear();
    },
    submitCancel() {
      this.$emit("onCancel");
      this._clear();
    },
    _clear() {
      this.$v.$reset();
      this.password = "";
    }
  },
  validations() {
    return {
      password: { required, minLength: minLength(3) }
    };
  }
};
</script>
